import React, { FC } from 'react';
import styles from './styles.module.scss';
import { TLink } from '../../../i18n/TLink';

interface IProps {
  url: string;
  page: number;
  children: any;
}

const ShowMoreButton: FC<IProps> = ({ children, url, page = 0 }) => {
  const to = page === 0 ? url : `${url}/page/${page}`;

  return (
    <TLink className={styles.button} to={to}>
      {children}
    </TLink>
  );
};

export { ShowMoreButton };
